import { get, post, put, del } from '@/http'
//暴露 用户相关的接口
const Api = {

    Config: {
        //协议
        Agreement: p => get('agreement/getInfo', p),
        //获取短信验证码
        SendSms: p => post('sms/send',p),
        //地址地区
        AreaOptions: p => get('area/areaOptions', p),
        // 首页数据初始化
        GetInit: p => get('index/index', p),

        indexwx: p => get('index/indexwx', p),
        //意见反馈
        Feedback: p => post('index/feedback', p),
        //公司简介
        CompanyProfile: p => post('index/companyProfile',p),
    },
    User: {
        //账号注册
        Register: p => post('user/register', p),
        //用户名登录
        AccountLogin: p => post('user/login', p),
        //验证码登录
        MobileLogin: p => post('user/mobilelogin',p),
        //个人中心
        UserInfo: p => get('user/index',p),
        //我的邀请
        UserInviteData: p => get('user/inviteData', p),
        //个人资料更新
        UserProfile: p => post('user/profile', p),
        //积分记录
        GetScoreLog: p => post('user/scoreLog',p),
        //修改密码
        ChangePwd: p => post('user/changePwd', p),
        //修改密码
        BindMobile: p => post('user/bindMobile', p),
        //实名认证
        TureName: p => post('user/truename', p),
        //退出登录
        UserLogout: p => post('user/logout', p),
        //用户简历信息
        userCV: p => get('user/userCV', p),
        //简历更新
        userCVupdate: p => post('user/userCVupdate',p),
        //简历头像
        UserCvAvatar: p => post('user/cvAvatar', p),
        //职业资格证书删除
        UserProImgDel: p => post('user/proImgDel',p),
        //职业资格证书 上传
        UserProEdit: p => post('user/proImgEdit', p),
        //获取职业资格证书列表
        UserProImgs: p => post('user/proImgs', p),
        getUserById: p => post('user/getUserById', p),
    },
    Article:{
        //资讯首页数据填充
        GetArticlesInit: p => get('article/init', p),
        //资讯列表
        GetArticles: p => get('article/lists', p),
        //资讯详情
        GetArticlesDetail: p => get('article/detail', p),
        Realpolicy: p => post('article/Realpolicy',p),
        Realpolicy1: p => post('article/Realpolicy1',p),
        getbyname: p => post('article/getbyname',p),
    },
    School: {
        //数据初始化
        getSchoolInit: p => get('school/init', p),
        //筛选列表
        getSchoolList: p => get('school/index', p),
        //校区详情
        GetSchoolDetail: p => get('school/detail', p),
        //校区视频
        GetSchoolVideo: p => get('school/videos',p),

        GetSchoolInfo: p => post('university/detail',p),
        // 获取院校特征信息
        GetSchooltraitInfo: p => get('university/other', p),
        // 获取学校列表
        GetSchoolList: p => post('university/getUnionList',p),
        // 获取学校专业列表 
        GetMajor: p => post('university/getmajordata', p),
        // 获取支付方式和学历类型
        education: p => post('signup/other', p),
        // 学历选择回调 获取专业signup/getMajorByEdutype
        getMajorByEdutype: p => post('signup/getMajorByEdutype', p),
        // 根据专业ID获取专业数据
        getMajorInfo: p => post('signup/getMajorInfo',p),
        // 根据学校id获取学历级别
        getEduLevelByUniversityId: p => post('signup/getEduLevelByUniversityId', p),
        screeninit:  p => post('signup/screeninit', p),
        getByvid:p => post('school/getByvid', p),
        getbyname:p => post('school/getbyname', p),
        schoollist:p => post('school/schoollist', p),
        getSchoolBytypeList:p => post('school/getSchoolBytypeList', p),
    },
    Applicants: {
        InsterEntity: p => post('Applicantss/add',p),
    },
    Collegedetails: {
        getAll: p=> post('Collegedetails/init',p),
        getBycc: p=> post('Collegedetails/getBycc',p),
    },
    Provideo:{
        getAll: p=> post('Provideo/init',p),
    },
    Area:{
        getbyname: p=> post('Area/getbyname',p),
        getbynames: p=> post('Area/getbynames',p),
        areaOptions: p=> post('Area/areaOptions',p),
        getByidarea: p=> post('Area/getByidarea',p),
    },
    Projectcheckin:{
        init: p=> post('Projectcheckin/init',p),
        getbyid: p=> post('Projectcheckin/getbyid',p),
        ALLInit: p=> post('Projectcheckin/ALLInit',p),
    },
    Teacher:{
        getBySchoolID: p=> post('Teacher/getBySchoolID',p),
    },
    Occupationallevel:{
        init:p=> post('Occupationallevel/init',p),
        ALLInit:p=> post('Occupationallevel/ALLInit',p),
    },
    Enrollmentpromotion:{
        init:p=> post('Enrollmentpromotion/init',p),
    },
    Applicationdemopro:{
        add:p=> post('Applicationdemopro/add',p),
    },
    Invitationcode:{
        init:p=>post('Invitationcode/init',p),
    },
    Trainingemploymentprojects:{
        init:p=>post('Trainingemploymentprojects/init',p),
    },Collegeadmissionprojects:{
        init:p=>post('Collegeadmissionprojects/init',p),
    },Oneemploymentprojects:{
        init:p=>post('Oneemploymentprojects/init',p),
    },Studentbrochure:{
        getByschoolname:p=>post('Studentbrochure/getByschoolname',p),
        getByid:p=>post('Studentbrochure/getByid',p),
        getByschoolid:p=>post('Studentbrochure/getByschoolid',p),
    },Studentbrochureappendix:{
        getByid:p=>post('Studentbrochureappendix/getByid',p),
    },

    //国家政策信息
    Nationalpolicy:{
        Realpolicy:p=>post('Nationalpolicy/Realpolicy',p),
        Realpolicy1:p=>post('Nationalpolicy/Realpolicy1',p),
        GetArticlesDetail:p=>post('Nationalpolicy/GetArticlesDetail',p),
        GetArticleslist:p=>post('Nationalpolicy/GetArticleslist',p),
    },
    //就业咨询/就业单个项目
    Temploymentindividual:{
        Realpolicy:p=>post('Temploymentindividual/Realpolicy',p),
        Realpolicy1:p=>post('Temploymentindividual/Realpolicy1',p),
        GetArticlesDetail:p=>post('Temploymentindividual/GetArticlesDetail',p),
        GetArticleslist:p=>post('Temploymentindividual/GetArticleslist',p),
    },
    //培训咨询
    Ttrainingandemployment:{
        Realpolicy:p=>post('Ttrainingandemployment/Realpolicy',p),
        Realpolicy1:p=>post('Ttrainingandemployment/Realpolicy1',p),
        GetArticlesDetail:p=>post('Ttrainingandemployment/GetArticlesDetail',p),
        GetArticleslist:p=>post('Ttrainingandemployment/GetArticleslist',p),
    },
    Userprefe:{
        Realpolicy:p=>post('Userprefe/Realpolicy',p),
        updateRealpolicy:p=>post('Userprefe/updateRealpolicy',p),
    },
    Preferential:{
        Realpolicy:p=>post('Preferential/Realpolicy',p),
    },
    Skillscompetition:{
        Realpolicy:p=>post('Skillscompetition/Realpolicy',p),
        Realpolicy1:p=>post('Skillscompetition/Realpolicy1',p),
        GetArticlesDetail:p=>post('Skillscompetition/GetArticlesDetail',p),
        GetArticleslist:p=>post('Skillscompetition/GetArticleslist',p),
    },
    Newuser:{
        getUserById:p=>post('Newuser/getUserById',p),
    },
    Chatrooms:{
        add:p=>post('Chatrooms/add',p),
        edit:p=>post('Chatrooms/edit',p),
        indexALL:p=>post('Chatrooms/indexALL',p),
        receivemessages:p=>post('Chatrooms/receivemessages',p),
    },
    Roomchats:{
        add:p=>post('Roomchats/add',p),
        getuserids:p=>post('Roomchats/getuserids',p),
        UpdateEducational:p=>post('Roomchats/UpdateEducational',p),
        getbyEducationalID:p=>post('Roomchats/getbyEducationalID',p),
    }



};
// 暴露出去
export default Api;
