<template>
  <div>
    <div>
      <div style="border: 1px solid #cccccc">
<!--        类型搜索-->
        <!-- 地图 -->
        <el-amap
            class="amap-box"
            :zoom="amap.zoom"
            :center="amap.center"
            :plugin="amap.plugin"
            :events="amap.events"
        >
          <!-- 当前位置图标 -->
          <el-amap-marker
              v-for="(marker, index) in amap.markers"
              :key="'marker' + index"
              :position="marker.position"
          />
          <!-- 位置名称显示 -->
          <el-amap-text
              v-for="(marker, index) in amap.markers"
              :key="'text' + index"
              :text="marker.text"
              :offset="marker.offset"
              :position="marker.position"
          />
        </el-amap>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const nonInterceptedAxios=axios.create();
//初始数据
function getFormData() {
  return {
    lat: "39.909186",
    lon: "116.39746",
    orgAddr: "天安门",
    province: "",
    city: "",
    district: "",
    searchResult: null,

  };
}
export default {
  name: "moxInLogaode",

  data() {
    const vm = this;
    return {
      //当前位置
      mapData:{},
      //用于存储学校名称对应的经纬度
      schoolLocation: null,
      // form对象
      dataForm: getFormData(),
      // 地图搜索对象
      searchOption: {
        city: "全国",
        citylimit: true,
      },
      addressNames:null,
      // //院校地址标记集合数据
      mapDatas: [
      ],
      //结束
      lng: 0,
      lat: 0,
      // 地图对象
      amap: {
        zoom: 16,
        center: [116.319802, 39.98294],
        events: {
          // 点击获取地址的数据
          click(e) {
            const { lng, lat } = e.lnglat;
            vm.dataForm.lon = lng;
            vm.dataForm.lat = lat;
            // 这里通过高德 SDK 完成。
            var geocoder = new AMap.Geocoder({
              radius: 100,
              extensions: "all",

            });
            geocoder.getAddress([lng, lat], function (status, result) {
              if (status === "complete" && result.info === "OK") {
                if (result && result.regeocode) {
                  console.log("点击获取地址的数据", result);
                  vm.dataForm.orgAddr = result.regeocode.formattedAddress;
                  vm.dataForm.province = result.regeocode.addressComponent.province;
                  vm.dataForm.city = result.regeocode.addressComponent.city;
                  vm.dataForm.district = result.regeocode.addressComponent.district;
                  vm.dataForm.lat = lat ? lat.toString() : "";
                  vm.dataForm.lon = lng ? lng.toString() : "";
                  vm.amap.markers = [];
                  const obj = {
                    position: [lng, lat],
                    text: result.regeocode.formattedAddress,
                    offset: [0, 30],
                  };
                  if (confirm("是否进行导航")) {
                    // https://ditu.amap.com/navi?start=+=113.659416,34.799353&end=113.663035,34.799799
                    const amapUrl = `https://uri.amap.com/marker?position=${lng},${lat}&name=${encodeURIComponent(obj.text)}&dev=0&t=0`;
                    // window.location.href = amapUrl;
                    window.open(amapUrl,"_blank");
                  }
                  vm.amap.markers.push(obj);
                  vm.sure();
                }
              }
            });
            vm.$nextTick();
          },
        },

        plugin: [
          {
            // 定位
            pName: "Geolocation",
            events: {
              init(o) {
                // o是高德地图定位插件实例
                o.getCurrentPosition((status, result) => {
                });
              },
            },
          }
        ],
        //
        markers: [],
      },
    };
  },
  created() {
    // 在地图初始化时移除默认版权控件

    setTimeout(() => {
      this.locateSchoolByName(this.$store.state.schooName);
    }, 2000);
  },
  methods: {
    // 地图搜索回调
    onSearchResult(pois) {
      const vm = this;
      vm.amap.markers = [];
      let latSum = 0;
      let lngSum = 0;
      console.log("地图回调", pois);
      if (pois.length > 0) {
        pois.forEach((poi, index) => {
          const {lng, lat} = poi;
          if (index === 0) {
            lngSum = lng;
            latSum = lat;
            const obj = {
              position: [poi.lng, poi.lat],
              text: poi.name,
              offset: [0, 30],
            };
            vm.amap.markers.push(obj);
            vm.dataForm.orgAddr = poi.name;
            vm.dataForm.lat = poi.lat ? poi.lat.toString() : "";
            vm.dataForm.lon = poi.lng ? poi.lng.toString() : "";
          }
        });
        vm.amap.center = [lngSum, latSum];
      }
    },
    // 根据学校名称定位学校位置
    async locateSchoolByName(schoolName) {
      try {
        // 假设您有一个接口可以返回学校名称对应的经纬度
        const response = await nonInterceptedAxios.get(
            `https://restapi.amap.com/v3/geocode/geo?key=fe4ee7ee1bca657b3f31a401f6b70fa7&address=${schoolName}`
        );

        // 保存学校经纬度
        this.schoolLocation = response.data.geocodes[0].location;

        // 根据学校经纬度更新地图状态
        this.updateMapWithSchoolLocation();
      } catch (error) {
        console.error("Failed to locate school by name:", error);
      }
    },
    // 根据学校经纬度更新地图状态
    updateMapWithSchoolLocation() {
      if (!this.schoolLocation) return;

      let coordinates = this.schoolLocation.split(",");

      let lng = coordinates[0];
      let lat = coordinates[1];

      // 更新地图中心点
      this.amap.center = [lng, lat];

      // 清空现有标记
      this.amap.markers = [];
      // 添加新的标记
      const obj = {
        position: [lng, lat],
        text: this.schooName,
        offset: [0, 30],
      };
      this.amap.markers.push(obj);

      // 更新表单数据
      this.dataForm.orgAddr = this.schooName;
      this.dataForm.lat = lat.toString();
      this.dataForm.lon = lng.toString();
      // 创建并添加多边形覆盖物，例如学校区域

      const positions = [
        [lng, lat - 0.001], // 稍微偏移一点以形成多边形
        [lng + 0.001, lat],
        [lng, lat + 0.001],
        [lng - 0.001, lat],
        [lng, lat - 0.001] // 闭合多边形
      ];
      // 触发地图重新渲染
      this.$nextTick();
    },
    // 提交方法
    sure() {
      const vm = this;
      console.log(this.amap.markers);
      this.$emit("updateLocation", vm.dataForm);
      },
  },
};
</script>
<style lang="scss" scoped>
#panel {
  position: fixed;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 10px;
  right: 10px;
  width: 280px;
  border-bottom: solid 1px silver;
}
#Map {
  width: 650px;
  height: 350px;
  margin-top: 10px;
}
.amap-box {
  height: 44vh;
}
.el-vue-search-box-container {
  // top: 45px;
  width: 100% !important;
}
.search-box {
  position: absolute;
  z-index: -1;
  width: 70%;
  left: 13%;
  top: 10px;
  height: 30px;
}

.search-box .el-input {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0 7px 7px 0;
  outline: none;
  position: relative;
}

.search-box .el-button {
  position: absolute;
  right: 0;
  top: 1px;
  width: 20%;
  background: #38a28a;
  border-radius: 0 7px 7px 0;
  border: none;
  color: #fff;
  outline: none;
}

.tip-box {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 35px;
  padding: 10px 0;
  background-color: #fff;
  opacity: 0.8;
}

</style>