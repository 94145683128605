import Vue from 'vue';
import VueRouter from 'vue-router'
import Home from '@/views/home.vue';
import Agreement from '@/views/agreement.vue';
import Login from '@/views/login.vue';
import Register from '@/views/register.vue';
import Articles from '@/views/articles/index.vue';
import ArticlesDetail from '@/views/articles/detail.vue';
import Person from '@/views/person/index.vue';
import PersonCV from '@/views/person/cv.vue';
import Repwd from '@/views/person/repwd.vue';
import Phone from '@/views/person/phone.vue';
import Invite from '@/views/person/invite.vue';
import ScoreLog from '@/views/person/score_log.vue';
import Truename from '@/views/person/true.vue';
import Feedback from '@/views/feedback.vue';
import About from '@/views/about.vue';
import Schools from '@/views/school/index.vue';
import School from '@/views/school/detail.vue';
import Selectaddress from '@/views/ModuleS/Selectaddress.vue'
import Selectgaode from '@/views/ModuleS/Selectgaode.vue'
import occupationallevelIndex from '@/views/occupationallevel/index.vue'
import Projects from '@/views/articles/Projects.vue'
import studentbrochure from '@/views/articles/studentbrochure.vue'
import mypackage from '@/views/person/mypackage.vue'
import onlineservice from '@/views/onlineservice/index.vue'
// 技能竞赛
import business from '@/views/business/index.vue'
// 就业咨询
import employmentproject from '@/views/localproject/employmentproject.vue'
// 就业咨询
import wallet from '@/views/person/wallet.vue'
// 静态协议
import agreementPAy from '@/views/xieyi/index.vue'

// 培训资讯
import Training from '@/views/Training/index.vue'

import policydocument from '@/views/localproject/policydocument.vue';
Vue.use(VueRouter);
const routes = [
    // + --------------  登录注册 --------------
    { path: '/login', component: Login,meta: {title: '中国教育网官网-官网登录入口'} },
    { path: '/register', component: Register,meta: {title: '中国教育网官网-账号注册入口'}},
    { path: '/agreement', component: Agreement},
    // + ------------------------------------------

    // + --------------  首页 --------------
    { path: '/', component: Home,meta:{title: '中国教育网官网-首页入口',requireAuth:true}},
    { path: '/feedback', component: Feedback,meta:{title: '中国教育网官网-首页入口',requireAuth:true}},
    { path: '/about', component: About,meta:{title: '中国教育网官网-首页入口',requireAuth:true}},
    { path: '/Selectaddress', component: Selectaddress,meta:{title: '中国教育网官网-首页入口',requireAuth:true}},
    { path: '/Selectgaode', component: Selectgaode,meta:{title: '中国教育网官网-首页入口',requireAuth:true}},

    // + -------------------------------------

    // + --------------  资讯 -----------------
    { path: '/article', component: ArticlesDetail,meta:{title: '中国教育网官网-咨询入口',requireAuth:true}},
    // + -------------------------------------

    // + --------------  个人中心 --------------
    { path: '/person', component: Person,meta:{title: '中国教育网官网-个人中心',requireAuth:true}},
    { path: '/person_cv', component: PersonCV,meta:{title: '中国教育网官网-个人中心',requireAuth:true}},
    { path: '/repwd', component: Repwd,meta:{title: '中国教育网官网-个人中心',requireAuth:true}},
    { path: '/phone', component: Phone,meta:{title: '中国教育网官网-个人中心',requireAuth:true}},
    { path: '/invite', component: Invite,meta:{title: '中国教育网官网-个人中心',requireAuth:true}},
    { path: '/truename', component: Truename,meta:{title: '中国教育网官网-个人中心',requireAuth:true}},
    { path: '/score_log', component: ScoreLog,meta:{title: '中国教育网官网-个人中心',requireAuth:true}},
    { path: '/mypackage', component: mypackage,meta:{title: '中国教育网官网-个人中心',requireAuth:true}},
    { path: '/wallet', component: wallet,meta:{title: '中国教育网官网-个人中心',requireAuth:true}},
    // + ------------------------------------------

    // + --------------  校区 --------------
    { path: '/schools', component: Schools,meta:{title: '中国教育网官网-院校入口',requireAuth:true}},
    { path: '/school', component: School,meta:{title: '中国教育网官网-院校入口',requireAuth:true}},
    { path: '/studentbrochure', component: studentbrochure,meta:{title: '中国教育网官网-院校入口',requireAuth:true}},
    // + ------------------------------------------


    // + --------------  职业证书 --------------
    { path: '/occupationallevelIndex', component: occupationallevelIndex,meta:{requireAuth:true}},
    { path: '/Projects', component: Projects,meta:{requireAuth:true}},
    // + ------------------------------------------

    // + --------------  在线客服 --------------
    { path: '/onlineservice', component: onlineservice,meta:{requireAuth:true}},
    // + ------------------------------------------

    // + --------------  技能竞赛 --------------
    { path: '/businessinde', component: business,meta:{title: '中国教育网官网-技能竞赛详情',requireAuth:true}},
    // + ------------------------------------------

    // + --------------  就业咨询 --------------
    { path: '/employmentproject', component: employmentproject,meta:{title: '中国教育网官网-就业咨询详情',requireAuth:true}},
    // + ------------------------------------------

    // + --------------  培训资讯 --------------
    { path: '/Training', component: Training,meta:{title: '中国教育网官网-培训资讯详情',requireAuth:true}},
    // + ------------------------------------------

    // + --------------  政策信息 --------------
    { path: '/policydocument', component: policydocument,meta:{title: '中国教育网官网-政策信息详情',requireAuth:true}},
    // + ------------------------------------------


    // + --------------  高德地图 --------------
    { path: '/Training', component: Training,meta:{title: '中国教育网官网-培训资讯详情',requireAuth:true}},
    // + ------------------------------------------

    // + --------------  用户充值协议 --------------
    { path: '/agreementPAy', component: agreementPAy,meta:{title: '中国教育网官网-用户充值协议',requireAuth:true}},
    // + ------------------------------------------
]

const router = new VueRouter({
    mode: 'history',
    routes: routes,
})

router.beforeEach((to, from, next) => {
    // 判断路由的 meta 字段中是否包含 title
    if (to.meta && to.meta.title) {
        // 设置页面标题
        document.title = to.meta.title;
    }
    next(); // 确保一定要调用 next()
});

export default router;