<template>
     <el-col :span="5">
        <router-link to="/person" :class="'item '+(currentRoute == '/person'?'active':'')">
            个人资料
        </router-link>
        <router-link to="/repwd" :class="'item '+(currentRoute == '/repwd'?'active':'')">
            修改密码
        </router-link>
        <router-link to="/phone" :class="'item '+(currentRoute == '/phone'?'active':'')">
            修改手机号
        </router-link>
        <router-link to="/truename" :class="'item '+(currentRoute == '/truename'?'active':'')">
            实名验证
        </router-link>
        <router-link to="/score_log" :class="'item '+(currentRoute == '/score_log'?'active':'')">
            积分明细
        </router-link>
        <router-link to="/invite" :class="'item '+(currentRoute == '/invite'?'active':'')">
            我的邀请
        </router-link>
        <router-link to="/person_cv" :class="'item '+(currentRoute == '/person_cv'?'active':'')">
            个人简历
        </router-link>
       <router-link to="/mypackage" :class="'item '+(currentRoute == '/mypackage'?'active':'')">
         我的卡包
       </router-link>
       <router-link to="/wallet" :class="'item '+(currentRoute == '/wallet'?'active':'')">
         我的钱包
       </router-link>
    </el-col>
</template>

<script>
export default {
    data() {
        return {
            currentRoute: this.$route.path,
        }
    },
    methods: {
        
    },
    watch: {
        $route(to, from) {
            this.currentRoute = to.path;
        },
    },
}
</script>

<style scoped lang="less">
.item{
        display: flex;
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-bottom: 5px;
        padding-left: 70px;
        color: #999;
        font-size: 18px;
        position: relative;
        overflow: hidden;
        text-decoration: none;
    }

    .item.active,
    .item:hover{
        background: #D11A2D;
        color: #ffffff;
    }

    .item.active:before,
    .item:hover:before{
        content: "";
        width: 18px;
        top: 0;
        right: 0;
        background: #f8fafc;
        position: absolute;
        height: 100%;
    }

    .item.active:after,
    .item:hover:after{
        content: "";
        border: 25px solid transparent;
        border-left: 18px solid #D11A2D;
        width: 0;
        height: 0;
        position: absolute;
        right: -25px;
        top: 0;
    }
</style>