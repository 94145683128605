<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <main class="page">
    <UserBanner></UserBanner>

    <div class="layout cont-box">
      <el-row style="width: 100%;">
        <!-- 菜单 -->
        <userNavs></userNavs>

        <!-- 内容 -->
        <el-col :span="19">
          <div class="main">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="1">我的账户</el-menu-item>
              <el-menu-item index="2" >充值端口</el-menu-item>
<!--              <el-menu-item index="3">交易记录</el-menu-item>-->
            </el-menu>
            <div v-if="activeIndex==1" >
              <el-row :gutter="20" type="flex" align="middle">
                <el-col :span="14">
                  <el-card class="balance-card">
                    <h2>账户余额</h2>
                    <p class="balance-info">0 积分</p>
                  </el-card>
                </el-col>
                <el-col :span="10">
                  <el-button type="primary" @click="activeIndex='2'" class="buy-btn" style="width: 50%;">购买积分</el-button>
                </el-col>
              </el-row>
            </div>
            <div v-if="activeIndex==2" >
              <div class="purchase-container">
                <p>
                  <span @click="setHUIyuan(0,68)" style="font-size: 20px;font-weight: bolder;cursor: pointer;">《购买积分》</span>
                  <span @click="setHUIyuan(1,1580)" style="font-size: 20px;font-weight: bolder;cursor: pointer;">《充值会员》</span>
                </p>
                <div v-if="aIndex==0">
                  <el-radio-group class="options" @change="onOptionSelected" v-model="selectedOption">
                    <el-row>
                      <el-col :span="6">
                        <el-radio-button :label="6">6 积分，价格：¥6.00</el-radio-button>
                      </el-col>
                      <el-col :span="6">
                        <el-radio-button :label="18">18 积分，价格：¥18.00</el-radio-button>
                      </el-col>
                      <el-col :span="6">
                        <el-radio-button :label="68">68 积分，价格：¥68.00</el-radio-button>
                      </el-col>
                      <el-col :span="6">
                        <el-radio-button :label="233">233 积分，价格：¥233.00</el-radio-button>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8">
                        <el-radio-button :label="648">648 积分，价格：¥648.00</el-radio-button>
                      </el-col>
                      <el-col :span="8">
                        <el-radio-button :label="998">998 积分，价格：¥998.00</el-radio-button>
                      </el-col>
                      <el-col :span="8">
                        <el-input v-model="customAmountInput" @input="validateInput" placeholder="自定义积分数量（1-5000）"></el-input>
                      </el-col>
                    </el-row>
                  </el-radio-group>
                </div>
                <div v-if="aIndex==1">
                  <el-radio-group class="options" @change="onOptionSelected" v-model="selectedOption">
                    <el-row>
                      <el-col :span="6">
                        <el-radio-button :label="1580">银卡 价格：¥1580</el-radio-button>
                      </el-col>
                      <el-col :span="6">
                        <el-radio-button :label="3580">金卡 价格：¥3580</el-radio-button>
                      </el-col>
                      <el-col :span="6">
                        <el-radio-button :label="5580">钻石 价格：¥5580</el-radio-button>
                      </el-col>
                      <el-col :span="6">
                        <el-radio-button :label="8880">合伙人 价格：¥8880</el-radio-button>
                      </el-col>
                    </el-row>
                  </el-radio-group>
                </div>

                <div class="qr-code-section">
                  <img :src="this.reservePay" title="由于业务问题,支付成功后请将付款截图转发给教务人员,后续调整成功会优化此流程" alt="由于业务问题,支付成功后请将付款截图转发给教务人员,后续调整成功会优化此流程" @click="toggleModal" class="qr-code" />
                  <div class="qr-code-details">
                    <p class="amount">¥{{customAmount}}</p>
                    <p class="agreement">请先阅读并同意支付相关协议</p>
                    <p class="agreement">温馨提示:微信暂时不可用</p>
                    <div class="payment-icons">
                      <img src="../../assets/images/alipay.jpg" alt="Alipay" class="payment-icon" />
                      <img src="../../assets/images/cnYINlian.jpg" alt="cnYINlian" class="payment-icon" />
                      <img src="../../assets/images/wxalpy.jpg" alt="cnYINlian" class="payment-icon" />
                    </div>
                    <div class="agreement-checkbox">
                      <input type="checkbox" @click="handleAgreementChange" id="agreement" />
                      <label for="agreement">我已阅读并同意<router-link to="/xieyiindex">《中国教育网用户充值协议》</router-link></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="activeIndex==3" >
              交易记录
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
        :visible.sync="isModalVisible"
        title="由于业务问题,支付成功后请将付款截图转发给教务人员,后续调整成功会优化此流程"
        width="20%"
        center
    >
      <el-image :src="this.reservePay"></el-image>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeModal">关闭</el-button>
      </span>
    </el-dialog>
  </main>
</template>
<script>
import UserBanner from "@/components/user/banner.vue";
import userNavs from "@/components/user/navs.vue";
import Api from "@/Api.js";
export default {
  data() {
    return {
      page: 1,
      dataList: [],
      activeIndex:'0',
      aIndex:0,
      selectedOption:null,
      customAmount:null,
      customAmountInput:null,
      reservePay:require('../../assets/images/Defaultdisplayofpaymentcode.png'),
      isModalVisible:false,
    }
  },
  components: {
    UserBanner,
    userNavs,
  },
  methods: {
    getDataLists() {
      let that = this;
      that.selectOption(68);
    },
    handleSelect(key, keyPath){
      if (key==1){

      }else if(key==2){

      }else  if(key==3){

      }
      this.activeIndex=key;
    },
    setHUIyuan(aIndex,customAmount){
      this.aIndex=aIndex;
      this.customAmount=customAmount;
    },
    onOptionSelected(value) {
      this.selectOption(value);
    },
    validateInput() {
      const amount = parseInt(this.customAmountInput, 10);
      // 首先检查输入是否为0或null
      if (amount === 0 || amount === null || amount === '') {
      } else {
        if (amount < 1 || amount > 5000) {
          this.$message.error('请输入1到5000之间的数字');
          this.customAmountInput = ''; // 清空输入框
        }else {
          this.selectOption(this.customAmountInput);
        }
      }
    },
    selectOption(amount) {
      this.customAmount = amount;
    },
    handleAgreementChange(event) {
      if (event.target.checked) {
        // 当复选框被选中时调用的方法
        this.showConfirmation();
      } else {
        // 当复选框未被选中时调用的方法（如果需要）
        this.clearConfirmation();
      }
    },
    showConfirmation() {
      this.reservePay=require('../../assets/images/formalPay.jpg');
      // let data={
      //   mchId:"43744776",//商户号
      //   outTradeNo:"1602833989984",//商户订单号
      //   timeExpire:"20201017153950",//交易结束时间(格式为yyyyMMddHHmmss)
      //   notifyUrl:"http://10.241.80.32:8082/payNotify",//商户通知回调地址
      //   nonceStr:"pFUuICf7GFHUGaAkiaWE5ZZNVRQzvNFy",//随机字符串
      //   totalFee:"10",//订单总金额(单位分)
      //   authCode:"134528746265137121",//微信或者支付宝的条码值
      //   body:"测试",//商品描述
      //   currency:"CNY",//标准币种
      //   clientIp:"127.0.0.1",//用户IP(H5支付时请传用户真实地址 ,支持IPV6)
      //   signType:"RSA",//签名类型
      //   sign: "cICZ8/00NUVTv0qp6zz/MxXp/3YNuHYWN3+/kTr1QSQJS6UtUKxDWLQ4jm6h7G3vyPdHZdLJeyGxmaAo6n7Vb7Bqai9BOSDPwdzW2NmsMp/W38g/" +
      //       "DW50Y6BYJrSnn8nFw2cqoJB8/pMVGoyIx4pmwKY7y3PBBAUyYe0FTYRhDjuoJ7xENeaCdkob3Cv9aQAvxPcdn2JNJa9xcpXpk7/xNmNTTNcpIUxBk+pPNv" +
      //       "qDSVHh8QRYYz7asbyo69qOqHtHD+GyDFduGC9nJaSkMu930clNFxkjnTLTVtryvc/aB2SKsoiAvqxGwXFQF4T5/VK4Nf1Kykxz0pcBSkPQh5e9EQ==",
      // }
      let data={
        "attach":"{\"latitude\":\"纬度\",\"bi\":\"基站信息\",\"longitude\":\"经度\"}",
        "authCode": "134528746265137121",
        "body": "聚合支付",
        "clientIp": "127.0.0.1",
        "currency": "CNY",
        "detail": "",
        "isNeedShare": "false",
        "mchId": "93751497",
        "nonceStr": "pFUuICf7GFHUGaAkiaWE5ZZNVRQzvNFy",
        "notifyUrl": "http://10.241.80.32:8082/payNotify",
        "outTradeNo": "1602833989984",
        "sign": "cICZ8/00NUVTv0qp6zz/MxXp/3YNuHYWN3+/kTr1QSQJS6UtUKxDWLQ4jm6h7G3vyPdHZdLJeyGxmaAo6n7Vb7Bqai9BOSDPwdzW2NmsMp/W38g/DW50Y6BYJrSnn8nFw2cqoJB8/pMVGoyIx4pmwKY7y3PBBAUyYe0FTYRhDjuoJ7xENeaCdkob3Cv9aQAvxPcdn2JNJa9xcpXpk7/xNmNTTNcpIUxBk+pPNvqDSVHh8QRYYz7asbyo69qOqHtHD+GyDFduGC9nJaSkMu930clNFxkjnTLTVtryvc/aB2SKsoiAvqxGwXFQF4T5/VK4Nf1Kykxz0pcBSkPQh5e9EQ==",
        "signType": "RSA",
        "timeExpire": "20201017153950",
        "totalFee": 10,
        "tradeType": ""
      }
      axios.post("https://mchapitest.shengpay.com/pay/authPay", data)
          .then(response => {
            console.log('Success:', response.data); // 处理成功的响应
          })
          .catch(error => {
            console.error('Error:', error); // 处理错误情况
          });
    },
    clearConfirmation() {
      this.reservePay=require('../../assets/images/Defaultdisplayofpaymentcode.png');
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  created() {
    this.getDataLists();
  },
}
</script>
<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.page{
  // padding: 20px 0;
  background: #f7f8fa;
  min-height: calc(100vh - 300px);
}

.cont-box{
  padding: 50px 0;
  display: flex;
}

.main{
  margin-left: 20px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  padding: 20px;
  position: relative;
}

/* 余额卡片样式 */
.balance-card {
  border: none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* 余额卡片标题样式 */
.balance-card h2 {
  font-size: 1.8em;
  color: #333;
  font-weight: bold;
}

/* 余额信息样式 */
.balance-info {
  color: #999;
  font-size: 1.4em;
  font-weight: bold;
}

/* 币券信息样式 */
.balance-info.coupon {
  font-size: 1.2em;
  color: #999;
}

/* 购买按钮样式 */
.buy-btn {
  font-size: 1.2em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
//充值端口
.purchase-container {
  text-align: center;
}

.options {
  margin-bottom: 20px;
}
.qr-code-section {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  margin-bottom: 20px; /* 外部间距 */
}

.qr-code {
  width: 200px; /* 根据需要调整二维码大小 */
  height: 200px;
  margin-right: 20px; /* 调整与详情部分的间距 */
}

.qr-code-details {
  display: flex;
  flex-direction: column;
  justify-content: center; /* 垂直居中对齐内部元素 */
}

.amount {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 10px; /* 调整与下一个元素的间距 */
}

.agreement {
  margin: 0 0 10px;
}

.payment-icons {
  display: flex;
  gap: 10px; /* 调整图标之间的间距 */
  margin-bottom: 10px; /* 与复选框的间距 */
}

.agreement-checkbox {
  margin-top: 10px;
}

//logo图片样式
.payment-icon {
  width: 100px;
  height: 100px;
  margin: 0.5px;
}

//模态框样式

</style>